export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    VERIFY_EMAIL_REQUEST: 'USERS_VERIFY_EMAIL_REQUEST',
    VERIFY_EMAIL_SUCCESS: 'USERS_VERIFY_EMAIL_SUCCESS',
    VERIFY_EMAIL_FAILURE: 'USERS_VERIFY_EMAIL_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    CHANGE_PASSWORD_REQUEST: 'USERS_CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'USERS_CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'USERS_CHANGE_PASSWORD_FAILURE',

    RESET_PASSWORD_REQUEST: 'USERS_RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'USERS_RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'USERS_RESET_PASSWORD_FAILURE',

    VALIDATE_RESET_PASSWORD_REQUEST: 'USERS_VALIDATE_RESET_PASSWORD_REQUEST',
    VALIDATE_RESET_PASSWORD_SUCCESS: 'USERS_VALIDATE_RESET_PASSWORD_SUCCESS',
    VALIDATE_RESET_PASSWORD_FAILURE: 'USERS_VALIDATE_RESET_PASSWORD_FAILURE',

    COMPLETE_RESET_PASSWORD_REQUEST: 'COMPLETE_VALIDATE_RESET_PASSWORD_REQUEST',
    COMPLETE_RESET_PASSWORD_SUCCESS: 'COMPLETE_VALIDATE_RESET_PASSWORD_SUCCESS',
    COMPLETE_RESET_PASSWORD_FAILURE: 'COMPLETE_VALIDATE_RESET_PASSWORD_FAILURE'
};
