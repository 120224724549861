/**
 * See the NOTICE file distributed with this work for additional information
 * regarding copyright ownership.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import Chip from '@mui/material/Chip';
import Box from "@mui/material/Box";

const styles = {
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: "15px",
        '& > *': {
            margin: 0.5,
        },
        "& .MuiChip-root": {
            borderRadius: 0
        }
    },
    tag_info: {
        backgroundColor: 'info.main',
        color: "white"
    },
    tag_success: {
        backgroundColor: 'success.main',
        color: "white"
    },
};

export default function Tags(props) {
    const {hubName, speciesScientificName, assemblyAccession} = props

    return (
        <Box sx={styles.root}>
            <Chip
                label={hubName}
                color="primary"
            />
            <Chip
                sx={styles.tag_success}
                label={speciesScientificName}
            />
            <Chip
                sx={styles.tag_info}
                label={assemblyAccession}
            />
        </Box>
    );
}
